<template>
    <div class="optimiser-progress-widget">
        <div class="col-2 d-flex align-items-center justify-content-between mb-2" style="padding-left:0px !important; padding-right:0px !important">
                Optimiser: 
                <b-form-select class="mr-2" v-model="currentOptimiser" :options="optimiserSelect" @change="optimiserChange"></b-form-select>
            </div>
        <div class="d-flex col-12">
            
            <div class="col-6 flex-column d-flex align-items-center">
               
                <span class="mb-2 font-weight-bold">Previous Weeks Portfolio</span>
                <b-table :items="tableOptions.prevItems" :fields="tableOptions.columns" responsive class="text-center"
                        show-empty sort-by="market" :sort-desc="false">
                        <template slot="empty">
                            No results found
                        </template>

                    </b-table>

            </div>
            <div class="col-6 flex-column d-flex align-items-center">
                    <span class="mb-2 font-weight-bold">Next Weeks Portfolio</span>
                    <b-table :items="tableOptions.nextItems" :fields="tableOptions.columns" responsive class="text-center"
                        show-empty sort-by="market" :sort-desc="false">
                        <template slot="empty">
                            No results found
                        </template>
                        <template v-slot:cell()="data">
                            <template v-if="data.field.key != 'market' && data.value != '-'">
                                <span v-if="Number(data.value) > Number(tableOptions.prevItems.find(item=>item.market == data.item.market)[data.field.key]) || tableOptions.nextItems.find(item=>item.market == data.item.market)[data.field.key] == '-'" style="color:green;">{{data.value}}</span>
                                <span v-else-if="Number(data.value) < Number(tableOptions.prevItems.find(item=>item.market == data.item.market)[data.field.key])" style="color:red;">{{data.value}}</span>
                                <span v-else>{{data.value}}</span>
                            </template>
                            <template v-else>{{data.value}}</template>

                        </template>
                        
                    </b-table>
            </div>
         </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue-daterange-picker-light';
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'optimiser-progress',
    data() {
        let scoreFormatter = (val)=>{
            if(isNaN(Number(val))){
                return val;
            }
            return Number(val).toFixed(2)
        }
        return {
            dateRange: {
                startDate: new Date(Date.now()),
            },
            tableOptions:{
                columns:[
                    {key:"market",value:"Market",sortable:true},
                    {key:"weight",value:"Weight",sortable:true},
                    {key:"maxScore",value:"Max Score",sortable:true,formatter:scoreFormatter},
                    {key:"averageScore",value:"Average Score",sortable:true,formatter:scoreFormatter},
                    {key:"totalScore",value:"Total Score",sortable:true,formatter:scoreFormatter},
                    ],
                prevItems:[],
                nextItems: [],
                
            },
            optimiserSelect:[],
            currentOptimiser:-1,
        };
    },
    components:{
        DateRangePicker,
        
    },
    props:{
        ID:{
            type:Number,
            default: null
        },
        parsedOptimiser:{
            type:String,
            default:null
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('optimiserprogress', this.onResponse);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('optimiserprogress', this.onResponse);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }
         
            if(this.parsedOptimiser != null){
                this.currentOptimiser = this.parsedOptimiser
            }
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'OptimiserProgress',
                args: {
                    optimiserId:this.currentOptimiser
                }
            });
        },
        optimiserChange(newOptimiser){
            if(!isNaN(Number(newOptimiser))){
                this.requests();
            }
        },
        onResponse(event) {
            if(event.response.hasOwnProperty("optimiser")){
                let optimisers = event.response.optimiser
                let values = Object.values(optimisers)
                let ids = values.map(x=> {return x.id})

                let formattedValues = [];
                for(let value of values){
                    formattedValues.push({value:value.id,text:value.name})
                }
                this.optimiserSelect = formattedValues;

                let current = values.find(x=>x.id == this.currentOptimiser);
                if(current == null || current == undefined){
                    this.currentOptimiser = Math.max(...ids);
                }

                this.$emit("getOptimisers", formattedValues, this.currentOptimiser, "cumulative")
            }
            if(!event.response.hasOwnProperty("next") || !event.response.hasOwnProperty("prev")){return;}
            let next = event.response.next
            let prev = event.response.prev
            //ensures tables have same keys
            let nextKeys = Object.keys(next);
            let prevKeys = Object.keys(prev);
            let nextDifference = prevKeys.filter(x=> !nextKeys.includes(x));
            let prevDifference = nextKeys.filter(x=> !prevKeys.includes(x));

            let emptyItem = {weight:"-",maxScore:"-",averageScore:"-",totalScore:"-"}
            //add to prev
            prevDifference.forEach(key=>{
                prev[key] = {market:key, ...emptyItem};
            })
            //add to next
            nextDifference.forEach(key=>{
                next[key] = {market:key, ...emptyItem};
            })

            //next table
            let nextItems = [];
            for(let data of Object.values(next)){
                nextItems.push(data);
            }
            this.tableOptions.nextItems = nextItems

            //previous table
            let prevItems = [];
            for(let data of Object.values(prev)){
                prevItems.push(data);
            }
            this.tableOptions.prevItems = prevItems

           if(this.ID != null){
            //alphabetically ordered
            prevItems = prevItems.sort(function(a, b) {
                var marketA = a.market.toUpperCase();
                var marketB = b.market.toUpperCase();
                return (marketA < marketB) ? -1 : (marketA > marketB) ? 1 : 0;
            });
            nextItems = nextItems.sort(function(a, b) {
                var marketA = a.market.toUpperCase();
                var marketB = b.market.toUpperCase();
                //always put sum/avg at bottom
                if(marketA == "SUM/AVG"){
                    return 1
                }else if(marketB == "SUM/AVG"){
                    return -1
                }
                return (marketA < marketB) ? -1 : (marketA > marketB) ? 1 : 0;
            });

            this.$emit("getRawData", [prevItems, nextItems], this.ID)
           }
        },
        onExport(){
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.items, clone);
            for(let item of items) {
                item.entryTime = format(item.entryTime, 'yyyy-MM-dd HH:mm:ss');
            }

            exportCsv(headers, items, 'OptimiserReturns');
        },
    }
}
</script>